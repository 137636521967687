import React, { useState, useEffect, useRef } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import { 
  Add, Delete, Mic, Refresh, Camera, 
  ExpandLess, ExpandMore, CheckCircleOutline, Search, Clear
} from '@mui/icons-material';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';
import { compressAccurately } from 'image-conversion';
import ReactConfetti from 'react-confetti';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import InputAdornment from '@mui/material/InputAdornment';
import Fab from '@mui/material/Fab';
import { useMediaQuery } from '@mui/material';
import { SwipeableList, SwipeableListItem } from 'react-swipeable-list';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import * as d3 from 'd3';
import { 
  LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend 
} from 'recharts';
import Carousel from 'react-material-ui-carousel';
import ExerciseCharts from '../components/ExerciseCharts';
import RecordRTC from 'recordrtc';

const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
const AIRTABLE_BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;
const AIRTABLE_TABLE_NAME = 'User Database'; // Hardcoded table name
const WEBHOOK_URL = process.env.REACT_APP_WEBHOOK_URL;

console.log('Environment Variables:', {
  AIRTABLE_API_KEY: AIRTABLE_API_KEY ? '[REDACTED]' : undefined,
  AIRTABLE_BASE_ID,
  AIRTABLE_TABLE_NAME,
  WEBHOOK_URL
});

console.log('Direct access to REACT_APP_AIRTABLE_TABLE_NAME:', process.env.REACT_APP_AIRTABLE_TABLE_NAME);

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0f172a',
      paper: '#1e293b',
    },
    primary: {
      main: '#60a5fa',
    },
    text: {
      primary: '#f1f5f9',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
      `,
    },
  },
});

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiSelect-select': {
    padding: '12px 16px',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SessionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.common.white,
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: theme.palette.grey[700],
  },
}));

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`;

const PulsatingMic = styled(Mic)(({ theme, isRecording }) => ({
  animation: isRecording ? `${pulse} 1.5s infinite` : 'none',
  color: isRecording ? theme.palette.error.main : 'inherit',
}));

const IconDumbbell = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="m6.5 6.5 11 11"/>
    <path d="m21 21-1-1"/>
    <path d="m3 3 1 1"/>
    <path d="m18 22 4-4"/>
    <path d="m2 6 4-4"/>
    <path d="m3 10 7-7"/>
    <path d="m14 21 7-7"/>
  </svg>
);

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  color: theme.palette.common.white,
  borderRadius: '50%',
  padding: 12,
  '&:hover': {
    backgroundColor: theme.palette.grey[600],
  },
}));

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlobs, setAudioBlobs] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [expandedSession, setExpandedSession] = useState(null);
  const [showPreviousSessions, setShowPreviousSessions] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const [newClientEmail, setNewClientEmail] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const mediaRecorderRef = useRef(null);

  const [isPreviousSessionsExpanded, setIsPreviousSessionsExpanded] = useState(false);
  const [expandedSessions, setExpandedSessions] = useState({});

  const [isChartModalOpen, setIsChartModalOpen] = useState(false);
  const [selectedSessionExercises, setSelectedSessionExercises] = useState([]);

  const [stream, setStream] = useState(null);

  const recorderRef = useRef(null);

  useEffect(() => {
    fetchClients();
    loadPersistedData();
    requestMicrophoneAccess(); // Request microphone access on component mount
  }, []);

  const requestMicrophoneAccess = async () => {
    try {
      // Check if the browser supports getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('getUserMedia is not supported in this browser');
      }

      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // If successful, stop the tracks to release the microphone
      stream.getTracks().forEach(track => track.stop());

      console.log('Microphone access granted');
      localStorage.setItem('microphonePermission', 'granted');
    } catch (error) {
      console.error('Error accessing microphone:', error);
      localStorage.setItem('microphonePermission', 'denied');
    }
  };

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      const permissionStatus = localStorage.getItem('microphonePermission');
      if (!permissionStatus) {
        await requestMicrophoneAccess();
      }
    };

    checkMicrophonePermission();
  }, []);

  useEffect(() => {
    persistData();
  }, [selectedClient, audioBlobs, photos]);

  const loadPersistedData = () => {
    const persistedData = localStorage.getItem('workoutData');
    if (persistedData) {
      const { selectedClient, audioBlobs, photos } = JSON.parse(persistedData);
      setSelectedClient(selectedClient || '');
      setAudioBlobs(audioBlobs || []);
      setPhotos(photos || []);
      if (selectedClient) {
        fetchSessions(selectedClient);
      }
    }
  };

  const persistData = () => {
    try {
      localStorage.setItem('workoutData', JSON.stringify({
        selectedClient,
        audioBlobs,
        photos
      }));
    } catch (error) {
      console.error('Error persisting data:', error);
    }
  };

  const resetWorkout = () => {
    setSelectedClient('');
    setAudioBlobs([]);
    setPhotos([]);
    localStorage.removeItem('workoutData');
  };

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      console.log('Fetching clients...');
      console.log('AIRTABLE_TABLE_NAME:', AIRTABLE_TABLE_NAME);
      const url = `https://api.airtable.com/v0/${encodeURIComponent(AIRTABLE_BASE_ID)}/${encodeURIComponent(AIRTABLE_TABLE_NAME)}?fields%5B%5D=Client+Name`;
      console.log('URL:', url);
      console.log('Authorization:', 'Bearer [REDACTED]');

      try {
        const response = await axios.get(url, {
          headers: { 
            Authorization: `Bearer ${AIRTABLE_API_KEY}`,
            'Content-Type': 'application/json'
          }
        });
        console.log('Response:', response.data);
        setClients(response.data.records.map(record => record.fields['Client Name']));
      } catch (error) {
        console.error('Error fetching clients:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClientChange = async (event) => {
    const clientName = event.target.value;
    if (clientName === 'add_new') {
      setOpenAddModal(true);
    } else {
      setSelectedClient(clientName);
      if (clientName) {
        await fetchSessions(clientName);
      } else {
        setSessions([]);
      }
    }
  };

  const fetchSessions = async (clientName) => {
    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/Sessions?filterByFormula={Client Name}='${clientName}'&sort%5B0%5D%5Bfield%5D=Date&sort%5B0%5D%5Bdirection%5D=desc`,
        { headers: { Authorization: `Bearer ${AIRTABLE_API_KEY}` } }
      );
      setSessions(response.data.records);
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
  };

  const toggleRecording = async () => {
    if (!isRecording) {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(audioStream);

        recorderRef.current = new RecordRTC(audioStream, {
          type: 'audio',
          mimeType: 'audio/webm',
          recorderType: RecordRTC.StereoAudioRecorder,
          numberOfAudioChannels: 1,
          desiredSampRate: 16000,
        });

        recorderRef.current.startRecording();
        setIsRecording(true);
      } catch (error) {
        console.error('Error accessing microphone:', error);
        alert("Error accessing microphone. Please ensure you have granted microphone permissions and your device supports audio recording.");
      }
    } else {
      if (recorderRef.current) {
        recorderRef.current.stopRecording(() => {
          const blob = recorderRef.current.getBlob();
          setAudioBlobs(prev => [...prev, {
            blob: blob,
            url: URL.createObjectURL(blob),
            type: blob.type
          }]);
          recorderRef.current.destroy();
          recorderRef.current = null;
          setIsRecording(false);
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
          }
        });
      }
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAudioBlobs(prev => [...prev, {
          blob: event.data,
          url: URL.createObjectURL(event.data),
          type: event.data.type
        }]);
      };
      reader.readAsDataURL(event.data);
    }
  };

  const handlePhotoUpload = async (event) => {
    const files = Array.from(event.target.files);
    const processedPhotos = await Promise.all(files.map(async file => {
      try {
        // Compress the image
        const compressedFile = await compressAccurately(file, {
          size: 200,    // target size in KB
          accuracy: 0.9
        });
        
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve({
            name: file.name,
            type: compressedFile.type,
            size: compressedFile.size,
            lastModified: file.lastModified,
            preview: reader.result
          });
          reader.onerror = reject;
          reader.readAsDataURL(compressedFile);
        });
      } catch (error) {
        console.error('Error compressing image:', error);
        // If compression fails, use the original file
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve({
            name: file.name,
            type: file.type,
            size: file.size,
            lastModified: file.lastModified,
            preview: reader.result
          });
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }
    }));
    
    setPhotos(prev => [...prev, ...processedPhotos]);
    console.log('Compressed photos added:', processedPhotos);
  };

  const deleteAudioBlob = (index) => {
    setAudioBlobs(prev => prev.filter((_, i) => i !== index));
  };

  const deletePhoto = (index) => {
    const photoToDelete = photos[index];
    if (photoToDelete.preview) {
      URL.revokeObjectURL(photoToDelete.preview);
    }
    setPhotos(prev => prev.filter((_, i) => i !== index));
  };

  const submitWorkout = async () => {
    if (audioBlobs.length === 0 && photos.length === 0) {
      alert('Please record at least one voice note or add a photo before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('clientName', selectedClient);

    audioBlobs.forEach((audio, index) => {
      const fileExtension = audio.type.split('/')[1];
      formData.append(`audio`, audio.blob, `recording${index + 1}.${fileExtension}`);
    });

    photos.forEach((photo, index) => {
      const blob = dataURItoBlob(photo.preview);
      formData.append(`photo`, blob, photo.name);
    });

    try {
      console.log('Submitting workout data');
      console.log('Client Name:', selectedClient);
      console.log('Number of audio recordings:', audioBlobs.length);
      console.log('Number of photos:', photos.length);

      const response = await axios.post(WEBHOOK_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      });
      console.log('Webhook response:', response.data);

      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
      setSuccessMessage('Workout Logged Successfully!');
      setShowSuccessMessage(true);
      resetWorkout();
      await fetchSessions(selectedClient);
    } catch (error) {
      console.error('Error sending data:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      alert(`Failed to log workout. Error: ${error.message}. Please check the console for more details.`);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const toggleSessionExpand = (sessionId) => {
    setExpandedSessions(prev => ({
      ...prev,
      [sessionId]: !prev[sessionId]
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  };

  const [successMessage, setSuccessMessage] = useState('');

  const handleAddClient = async () => {
    if (newClientName.trim()) {
      try {
        const fields = { 'Client Name': newClientName.trim() };
        if (newClientEmail.trim()) {
          fields['E-Mail Address'] = newClientEmail.trim();
        }

        const response = await axios.post(
          `https://api.airtable.com/v0/${encodeURIComponent(AIRTABLE_BASE_ID)}/${encodeURIComponent(AIRTABLE_TABLE_NAME)}`,
          { fields },
          { 
            headers: { 
              Authorization: `Bearer ${AIRTABLE_API_KEY}`, 
              'Content-Type': 'application/json' 
            } 
          }
        );

        setClients([...clients, newClientName.trim()]);
        setNewClientName('');
        setNewClientEmail('');
        setOpenAddModal(false);
        setSelectedClient(newClientName.trim());
        
        setSuccessMessage('New client created successfully!');
        setShowSuccessMessage(true);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
      } catch (error) {
        console.error('Error adding client:', error);
        alert('Failed to add client. Please try again.');
      }
    }
  };

  const handleDeleteClient = async (clientName) => {
    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}?filterByFormula={Client Name}='${clientName}'`,
        { headers: { Authorization: `Bearer ${AIRTABLE_API_KEY}` } }
      );
      
      if (response.data.records.length > 0) {
        const recordId = response.data.records[0].id;
        await axios.delete(
          `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}/${recordId}`,
          { headers: { Authorization: `Bearer ${AIRTABLE_API_KEY}` } }
        );
        setClients(clients.filter(client => client !== clientName));
        if (selectedClient === clientName) {
          setSelectedClient('');
        }
        setDeleteConfirmation(null);
        setSuccessMessage('Client deleted successfully!');
        setShowSuccessMessage(true);
      }
    } catch (error) {
      console.error('Error deleting client:', error);
      alert('Failed to delete client. Please try again.');
    }
  };

  const handleCloseSuccessMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccessMessage(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const filteredClients = clients.filter(client =>
    client.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const togglePreviousSessions = () => {
    setIsPreviousSessionsExpanded(prev => !prev);
  };

  // Skeleton loader component
  const SkeletonLoader = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {[1, 2, 3].map((item) => (
        <Box key={item} sx={{ height: 40, bgcolor: 'grey.700', borderRadius: 1 }} />
      ))}
    </Box>
  );

  useEffect(() => {
    return () => {
      // Cleanup function
      if (recorderRef.current) {
        recorderRef.current.destroy();
        recorderRef.current = null;
      }
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <div className="app-container">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm" sx={{ py: 2 }}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h4"
              component="h1"
              align="center"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                background: 'linear-gradient(to right, #60a5fa, #8b5cf6)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Log Workout
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
              <Box sx={{ width: 64, height: 2, bgcolor: '#60a5fa', borderRadius: 'full', mr: 2 }} />
              <IconDumbbell />
              <Box sx={{ width: 64, height: 2, bgcolor: '#8b5cf6', borderRadius: 'full', ml: 2 }} />
            </Box>
          </motion.div>
          
          <Box sx={{ bgcolor: 'grey.800', borderRadius: 2, p: 3, mb: 4, boxShadow: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <StyledSelect
                value={selectedClient}
                onChange={handleClientChange}
                sx={{ flexGrow: 1, mr: 2 }}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <TextField
                        fullWidth
                        placeholder="Search clients..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                          endAdornment: searchTerm && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClearSearch} edge="end" size="small">
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    );
                  }
                  return selected;
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select User</em>
                </MenuItem>
                {isLoading ? (
                  <SkeletonLoader />
                ) : (
                  filteredClients.map(client => (
                    <StyledMenuItem 
                      key={client} 
                      value={client} 
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        pr: 1,
                      }}
                    >
                      <Typography sx={{ flexGrow: 1 }}>{client}</Typography>
                      <IconButton
                        size="small"
                        sx={{ 
                          opacity: 0.7,
                          color: 'text.secondary',
                          '&:hover': { 
                            opacity: 1, 
                            color: 'error.main' 
                          },
                          padding: '8px',
                          ml: 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteConfirmation(client);
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledMenuItem>
                  ))
                )}
                <StyledMenuItem 
                  value="add_new" 
                  sx={{ 
                    color: 'primary.main',
                    bgcolor: 'action.selected',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <Add sx={{ mr: 1 }} /> Add Client
                </StyledMenuItem>
              </StyledSelect>
              <Tooltip title="Reset Workout">
                <StyledIconButton onClick={resetWorkout} size="small">
                  <Refresh />
                </StyledIconButton>
              </Tooltip>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Tooltip title={isRecording ? 'Stop Recording' : 'Start Recording'}>
                <StyledButton
                  variant="contained"
                  color={isRecording ? 'error' : 'primary'}
                  onClick={toggleRecording}
                  startIcon={<PulsatingMic isRecording={isRecording} />}
                  sx={{ flexGrow: 1, mr: 2 }}
                >
                  {isRecording ? 'Stop Recording' : 'Start Recording'}
                </StyledButton>
              </Tooltip>
              <Tooltip title="Add Photo">
                <StyledButton
                  variant="contained"
                  color="primary"
                  component="label"
                  startIcon={<Camera />}
                  sx={{ flexGrow: 1 }}
                >
                  Add Photo
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={handlePhotoUpload}
                    hidden
                  />
                </StyledButton>
              </Tooltip>
            </Box>
            
            {audioBlobs.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Audio Recordings
                </Typography>
                <SwipeableList>
                  {audioBlobs.map((audio, index) => (
                    <SwipeableListItem
                      key={index}
                      onSwipeRight={() => deleteAudioBlob(index)}
                      swipeRightThreshold={100}
                      swipeLeftThreshold={100}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          bgcolor: 'grey.700',
                          borderRadius: 1,
                          p: 1,
                          mb: 1,
                        }}
                      >
                        <audio controls src={audio.url} />
                        <IconButton
                          size="small"
                          onClick={() => deleteAudioBlob(index)}
                          sx={{ ml: 1 }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                    </SwipeableListItem>
                  ))}
                </SwipeableList>
              </Box>
            )}
            
            {photos.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Photos
                </Typography>
                <SwipeableList>
                  {photos.map((photo, index) => (
                    <SwipeableListItem
                      key={index}
                      onSwipeRight={() => deletePhoto(index)}
                      swipeRightThreshold={100}
                      swipeLeftThreshold={100}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          bgcolor: 'grey.700',
                          borderRadius: 1,
                          p: 1,
                          mb: 1,
                        }}
                      >
                        <img
                          src={photo.preview}
                          alt={`Preview ${index + 1}`}
                          style={{ maxWidth: '100%', maxHeight: '100px' }}
                        />
                        <IconButton
                          size="small"
                          onClick={() => deletePhoto(index)}
                          sx={{ ml: 1 }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                    </SwipeableListItem>
                  ))}
                </SwipeableList>
              </Box>
            )}
            
            <Button 
              fullWidth 
              variant="contained" 
              color="primary"
              onClick={submitWorkout}
              startIcon={<Add />}
            >
              Log Workout
            </Button>
          </Box>

          {sessions.length > 0 && (
            <SessionBox>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Previous Sessions</Typography>
                <IconButton onClick={togglePreviousSessions}>
                  {isPreviousSessionsExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={isPreviousSessionsExpanded}>
                <AnimatePresence>
                  {sessions.map((session) => (
                    <motion.div
                      key={session.id}
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Box
                        sx={{
                          bgcolor: 'grey.800',
                          borderRadius: 2,
                          p: 2,
                          mb: 2,
                          boxShadow: 2,
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="subtitle1">
                            {formatDate(session.fields.Date)} - {session.fields['Muscle Group']}
                          </Typography>
                          <IconButton onClick={() => toggleSessionExpand(session.id)}>
                            {expandedSessions[session.id] ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Box>
                        <Collapse in={expandedSessions[session.id]}>
                          {session.fields['Exercise Name'] && (
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="subtitle2">Exercise Name:</Typography>
                              <ul>
                                {session.fields['Exercise Name'].map((exercise, index) => (
                                  <li key={index}>{exercise}</li>
                                ))}
                              </ul>
                            </Box>
                          )}
                        </Collapse>
                      </Box>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Collapse>
            </SessionBox>
          )}

          <Modal
            open={openAddModal}
            onClose={() => setOpenAddModal(false)}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openAddModal}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                  Add New Client
                </Typography>
                <TextField
                  label="Client Name"
                  fullWidth
                  value={newClientName}
                  onChange={(e) => setNewClientName(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="E-Mail Address"
                  fullWidth
                  value={newClientEmail}
                  onChange={(e) => setNewClientEmail(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddClient}
                  startIcon={<Add />}
                  fullWidth
                >
                  Add Client
                </Button>
              </Box>
            </Fade>
          </Modal>

          <Modal
            open={!!deleteConfirmation}
            onClose={() => setDeleteConfirmation(null)}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={!!deleteConfirmation}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                  Confirm Deletion
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Are you sure you want to delete {deleteConfirmation}?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteClient(deleteConfirmation)}
                    startIcon={<Delete />}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDeleteConfirmation(null)}
                    startIcon={<Clear />}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>

          <ReactConfetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={showConfetti ? 100 : 0}
            recycle={false}
            gravity={0.1}
            tweenDuration={5000}
          />

          <Snackbar
            open={showSuccessMessage}
            autoHideDuration={6000}
            onClose={handleCloseSuccessMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSuccessMessage}
              severity="success"
              sx={{ width: '100%' }}
              
            >
              {successMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </div>
  );
};


export default Dashboard;
